import React, { ChangeEvent, FormEvent, MouseEvent } from 'react'; import SearchIcon from './icons/SearchIcon';
import { XCircleIcon } from '@heroicons/react/20/solid';

interface SearchFormProps {
    inputVal: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
    onClear: (event: MouseEvent<SVGElement>) => void;
};

export const SearchForm: React.FC<SearchFormProps> = ({ inputVal, onChange, onSubmit, onClear }) => {
    return (
        <form className="w-full" onSubmit={onSubmit}>
            <label
                htmlFor="search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
                Search
            </label>
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <SearchIcon strokeWidth={2} className="w-4 h-4 text-gray-500" />
                </div>
                <input
                    id="search"
                    value={inputVal}
                    onChange={onChange}
                    className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                    placeholder="제목을 입력해주세요"
                />
                {inputVal.length > 0 &&
                    <div className="absolute inset-y-0 end-0 flex items-center pe-4">
                        <XCircleIcon onClick={onClear} className="w-5 h-5 text-gray-400" />
                    </div>
                }
            </div>
        </form>
    );
};