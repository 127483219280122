/** 평점 소수점 포맷
 * 
 * - 10 이하: 소수점 두자리
 * - 10: 소수점 한자리
 */
export const formatScore = (score: number): string => {
    if(score >= 10){
        return score.toFixed(1)
    }
    else{
        return score.toFixed(2);
    }
}