/** 설명 본문의 첫 줄과 나머지 줄 분리
 * 
 * `.!?` 를 기준으로 분리함
 * - `[0]`: 첫 라인 혹은 빈 문자열(예외처리)
 * - `[1]`: 나머지 라인
 */
export const splitDescription = (description: string): string[] => {
    const first = description.match(/^(.*?)(?:\n|$)/);
    if (!first || !first[0]) {
        return ['', description];
    }
    const remain = description.replace(first[0], '').trim(); // 나머지 문장
    return [first[0], remain];
}