import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { GetEpisodeCutItem } from "@src/redux/interfaces/content/getEpisodeCut";
import { useCallback, useEffect, useRef, useState } from "react";
import "./clip-half.css";
import { useSetEpisodeScoreMutation } from "@src/redux/servicesNew/content";
import { ContentTypes } from "@src/redux/enums/content";

export const RatingModal = ({ cut, type, onClose, score }: { cut: GetEpisodeCutItem; type: ContentTypes; onClose: () => void; score?: number; }) => {
    const [rating, setRating] = useState<number>(5);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const ratingContainerRef = useRef<HTMLDivElement>(null);

    const [setEpisodeScore, { isSuccess: seIsSuccess, error: seError, isLoading: seIsLoading }] = useSetEpisodeScoreMutation();

    useEffect(() => {
        if (score) {
            setRating(score / 2);
        }
    }, [score])

    useEffect(() => {
        if (seIsSuccess) {
            onClose();
        }
        else if (seError) {
            console.error(seError);
        }
    }, [seIsSuccess]);

    const calculateRating = useCallback((clientX: number): void => {
        const container = ratingContainerRef.current;
        if (!container) return;

        const rect = container.getBoundingClientRect();
        const starWidth = rect.width / 5;
        const position = clientX - rect.left;

        let newRating = Math.round((position / starWidth) * 2) / 2;
        newRating = Math.max(0, Math.min(5, newRating));

        setRating(newRating);
    }, []);

    // 마우스 이벤트 핸들러
    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>): void => {
        setIsDragging(true);
        calculateRating(e.clientX);
    };

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (!isDragging) {
            return;
        }
        calculateRating(e.clientX);
    };

    const handleMouseUp = (): void => {
        setIsDragging(false);
    };

    const handleMouseLeave = (): void => {
        setIsDragging(false);
    };

    // 터치 이벤트 핸들러
    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>): void => {
        setIsDragging(true);
        calculateRating(e.touches[0].clientX);

    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>): void => {
        if (!isDragging) {
            return;
        }
        calculateRating(e.touches[0].clientX);

    };

    const handleTouchEnd = (): void => {
        setIsDragging(false);
    };

    const handleSubmit = async () => {
        if (rating == 0) {
            return;
        }
        await setEpisodeScore({
            EpisodeId: cut.titleID,
            type: type,
            score: rating * 2,
        });
    }

    return (
        <div className="w-screen h-screen fixed z-30 bg-black/50 font-notokr" onClick={onClose}>
            <div className="flex w-full h-full justify-center items-center px-16">
                <div
                    className={`flex flex-col bg-white w-full z-30 rounded-xl shadow-md max-w-[400px] gap-4 p-4 ${seIsLoading && 'animate-pulse pointer-events-none'}`}
                    onClick={(e) => e.stopPropagation()}>
                    <div className="flex justify-center mt-2">
                        <span className="text-red-500 text-4xl font-semibold">{rating * 2}</span>
                    </div>
                    <div
                        ref={ratingContainerRef}
                        className="relative flex justify-center space-x-1 select-none touch-none cursor-pointer px-2 mx-2"
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onMouseLeave={handleMouseLeave}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                    >
                        {[1, 2, 3, 4, 5].map((star) => (
                            <div key={star} className="relative">
                                <StarIconOutline className="w-full h-full text-red-500" />
                                {star <= rating ?
                                    <div className="absolute inset-0 overflow-hidden">
                                        <StarIcon className="w-full h-full text-red-500" />
                                    </div>
                                    : star - 0.5 === rating ?
                                        <div className="absolute inset-0 overflow-hidden clip-half">
                                            <StarIcon className="w-full h-full text-red-500" />
                                        </div>
                                        : null
                                }
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center">
                        <span className="text-gray-500">좌우로 드래그하세요.</span>
                    </div>
                    <div className="flex gap-2 mt-2">
                        <button className="flex-1 text-white bg-gray-300 rounded-xl p-2" onClick={onClose}>
                            취소
                        </button>
                        <button className="flex-1 text-white bg-alco-mint rounded-xl p-2" onClick={handleSubmit}>
                            확인
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}