import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "@store";

export const allComicsApi = createApi({
    reducerPath: 'allComicsApi',
    tagTypes: ['Purchase', 'Payment', 'Favorite', 'Score'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_SERVER_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).storage.session.globalUserSlice.session_token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: () => ({}),
});