import React from "react";

interface BackIconProps {
    fill?: string;
    stroke?: string;
    strokeWidth?: number;
    onClick?: () => void;
    className?: string;
}

const BackIcon: React.FC<BackIconProps> = ({ fill, stroke, strokeWidth, onClick, className }) => {

    return (
        <svg
            className={className}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill ?? "none"}
            stroke={stroke}
            strokeWidth={strokeWidth}
            viewBox="0 0 80 80"
            onClick={onClick}
        >
            <circle cx="40" cy="40" r="39" fill="white" stroke="#d0d0d0" strokeWidth={2}></circle>
            <path d="M24 24L24 56H22L22 24H24ZM33 41L44 52L42.5 53.5L29 40L42.5 27L44 28.5L33 39L59 39V41H33Z" fill="#09121f"></path>
        </svg>
    );
};

export default BackIcon;