/** 조회수 포맷
 * - 100 이상: 100+, 200+, 300+,....,900+
 * - 1000이상: 1천+, 2천+,...., 9천+
 * - 만 이상: 1만+,...,9만+
 * - 십만 이상: 10만+,...,90만+
 * - 백만 이상: 100만+,..., 900만+
 */
export const formatView = (view: number): string => {
    if (view < 100) {
        return view.toString(); // 100 미만은 그대로 반환
    } else if (view < 1000) {
        return `${Math.floor(view / 100) * 100}+`; // 100 이상 1000 미만
    } else if (view < 10000) {
        return `${Math.floor(view / 1000)}천+`; // 1000 이상 1만 미만
    } else if (view < 100000) {
        return `${Math.floor(view / 10000)}만+`; // 1만 이상 10만 미만
    } else if (view < 1000000) {
        return `${Math.floor(view / 100000)}0만+`; // 10만 이상 100만 미만
    } else {
        return `${Math.floor(view / 1000000)}00만+`; // 100만 이상
    }
}
