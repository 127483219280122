import CarouselComponent from "@components/banner/Banner";
import { Footer } from "@components/Footer";
import { Header } from "@components/Header";
import { IsError } from "@components/IsError";
import { NoData } from "@components/NoData";
import { Spinner } from "@components/Spinner";
import { CalendarDaysIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { useStorage } from "@hooks/useStorage";
import { ContentCategory, ContentTypes, validateOption } from "@src/redux/enums/content";
import { GetCategoryContentItem } from "@src/redux/interfaces/content/getCategory";
import { useGetBannerQuery } from "@src/redux/servicesNew/banner";
import { useLazyGetCategoryContentQuery } from "@src/redux/servicesNew/content";
import { formatGenre } from "@src/utils/formatGenre";
import { getBannerCategory } from "@src/utils/getBannerCategory";
import { RootState } from "@store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const Title = ({ contentType, category }: { contentType: ContentTypes; category: ContentCategory; }) => {
    switch (category) {
        case ContentCategory.TODAY_FREE:
            return (
                <>
                    <div>
                        <span className="text-alco-mint">오늘만 무료 </span>
                        <span>작품</span>
                    </div>
                    <CalendarDaysIcon className="fill-white bg-yellow-500 rounded-full p-1 w-7 h-7 lg:w-9 lg:h-9" />
                </>
            );
        case ContentCategory.POPULAR:
            return (
                <>
                    <div>
                        <span className="text-alco-mint">인기 추천 </span>
                        <span>작품</span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="inline fill-white bg-yellow-500 rounded-full p-1.5 w-7 h-7 lg:w-9 lg:h-9">
                        <g>
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M14.6 8H21a2 2 0 0 1 2 2v2.104a2 2 0 0 1-.15.762l-3.095 7.515a1 1 0 0 1-.925.619H2a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3.482a1 1 0 0 0 .817-.423L11.752.85a.5.5 0 0 1 .632-.159l1.814.907a2.5 2.5 0 0 1 1.305 2.853L14.6 8zM7 10.588V19h11.16L21 12.104V10h-6.4a2 2 0 0 1-1.938-2.493l.903-3.548a.5.5 0 0 0-.261-.571l-.661-.33-4.71 6.672c-.25.354-.57.644-.933.858zM5 11H3v8h2v-8z">
                            </path>
                        </g>
                    </svg>
                </>
            );
        case ContentCategory.NEW:
            return (
                <>
                    <div>
                        <span className="text-alco-mint">신규 </span>
                        <span>작품</span>
                    </div>
                    <div className="flex justify-center items-center bg-yellow-500 rounded-full p-1 w-7 h-7 lg:w-9 lg:h-9">
                        <span className="text-white text-[0.6rem] lg:text-xs">NEW</span>
                    </div>
                </>
            );
        case ContentCategory.CHALLENGE:
            return (
                <div>
                    <span className="text-alco-yellow">도전! </span>
                    <span>밀리언 {contentType === ContentTypes.WEBTOON ? "웹툰" : "소설"}</span>
                </div>
            );
        default:
            return null;
    }
}

export const Category = () => {
    const category = validateOption(useParams()['category'], ContentCategory, ContentCategory.TODAY_FREE);
    const [params, setParams] = useSearchParams();
    const page = Number(params.get("page")) || 1;
    const contentType = validateOption(Number(params.get("type")), ContentTypes, ContentTypes.WEBTOON);
    const [maxPage, setMaxPage] = useState(0);

    const { isLoggedIn } = useStorage();
    const currentDomain = useSelector((state: RootState) => state.domain.currentDomain);
    const { data: bData, error: bError, isFetching: bIsFetching } = useGetBannerQuery({ page: 1, count: 10, category: getBannerCategory(currentDomain), SubCategory: 'main', Locale: 'ko', view_type: 0 });
    const [ccTrigger, { data: ccData, error: ccError, isFetching: ccIsFetching }] = useLazyGetCategoryContentQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if (!page || contentType === null || !category) {
            return;
        }
        ccTrigger({
            page, count: 10, type: contentType, language: 'ko', category,
        }, true);
    }, [page, contentType, category]);

    useEffect(() => {
        if (!ccData) {
            return;
        }
        const total = ccData.data.total;
        const count = ccData.data.count;
        setMaxPage(Math.ceil(total / count));
    }, [ccData]);

    const toggleType = (type: ContentTypes) => {
        params.set('type', type.toString());
        params.set('page', "1");
        setParams(params);
    }

    const movePage = (arg: number) => {
        if (arg > maxPage || arg < 0) {
            return;
        }
        params.set('page', arg.toString());
        setParams(params);
    }

    const viewEpisode = (content: GetCategoryContentItem) => {
        if (!isLoggedIn) {
            return navigate('/login');
        }
        navigate(`/content/episode/${content.webtoon_id || content.novel_id}_E001?type=${contentType}`);
    }

    const viewContent = (content: GetCategoryContentItem) => {
        navigate(`/content/${content.webtoon_id || content.novel_id}?type=${contentType}`);
    }

    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            {bIsFetching ?
                <div className="flex justify-center items-center h-[30vh]">
                    <Spinner />
                </div>
                : bData ?
                    <div>
                        <CarouselComponent data={bData.data.data.banner} />
                    </div>
                    : bError ? <IsError /> : <NoData />
            }
            <div className="flex flex-col w-full max-w-[1200px] mx-auto font-notokr gap-4 px-4 pt-4 md:pt-14 pb-20">
                {/* 리스트 제목 */}
                <div className="flex items-center font-bold text-lg lg:text-3xl gap-2 lg:gap-4">
                    <Title contentType={contentType} category={category} />
                </div>
                {/* 리스트 옵션 */}
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2">
                    <div className="flex-[2] text-sm md:text-base font-medium">
                        <div className="flex">
                            <div className="flex gap-2">
                                <button
                                    onClick={() => toggleType(ContentTypes.WEBTOON)}
                                    className={`${contentType == ContentTypes.WEBTOON ? "text-alco-mint" : "text-gray-400"}`}
                                >
                                    <span className="leading-normal">웹툰</span>
                                </button>
                                <button
                                    onClick={() => toggleType(ContentTypes.WEBNOVEL)}
                                    className={`${contentType == ContentTypes.WEBNOVEL ? "text-alco-mint" : "text-gray-400"}`}
                                >
                                    <span className="leading-normal">웹소설</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 작품 리스트 */}
                {ccIsFetching && !ccData ?
                    <div className="flex justify-center items-center h-[30vh]">
                        <Spinner />
                    </div>
                    : ccData ?
                        <div className={`flex flex-col gap-4 ${ccIsFetching && "opacity-50 pointer-events-none"}`}>
                            <div className="grid grid-cols-1 md:grid-cols-2">
                                {ccData.data.list.map((item, index) => (
                                    <div className="flex items-center border-b-2 gap-4 lg:gap-8 p-4" key={index}>
                                        <div className="flex-[1.8]">
                                            <img className="rounded-2xl drop-shadow-xl" src={item.thumbnail || item.thumbnails?.thumbnail} />
                                        </div>
                                        <div className="flex-[4] flex flex-col justify-center items-start gap-1.5 md:gap-2">
                                            <span className="lg:text-xl font-medium">{item.webtoon_name || item.novel_name}</span>
                                            <span className="text-sm md:text-base text-gray-500">{formatGenre(item.genres)}</span>
                                            <button
                                                className="text-sm md:text-base px-2 py-1 md:px-4 md:py-2 text-white bg-alco-mint rounded-lg"
                                                onClick={() => viewEpisode(item)}>
                                                첫화보기
                                            </button>
                                        </div>
                                        <div className="flex-[1]">
                                            <button onClick={() => viewContent(item)}>
                                                <Bars3Icon
                                                    className="w-8 h-8 lg:w-12 lg:h-12" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-end gap-4">
                                <button
                                    className={`bg-gray-100 text-gray-400 border-gray-400 border-2 rounded-lg ${page === 1 && "opacity-50 pointer-events-none"}`}
                                    onClick={() => movePage(page - 1)}>
                                    <ChevronLeftIcon className="w-8 h-8" />
                                </button>
                                <button
                                    className={`bg-gray-100 text-gray-400 border-gray-400 border-2 rounded-lg ${page >= maxPage && "opacity-50 pointer-events-none"}`}
                                    onClick={() => movePage(page + 1)}
                                >
                                    <ChevronRightIcon className="w-8 h-8" />
                                </button>
                            </div>
                        </div>
                        : ccError ? <IsError /> : <NoData />
                }

            </div>
            <Footer />
        </div>
    );
}