import { createSlice } from '@reduxjs/toolkit';
import { SiteDomain } from '@src/redux/enums/domain';

const hostname = window.location.hostname;
let initialDomain: SiteDomain;

if (hostname.includes("lpoint")) {
    initialDomain = SiteDomain.LPOINT;
} else if (hostname.includes("shinhan")) {
    initialDomain = SiteDomain.SHINHAN;
} else {
    initialDomain = SiteDomain.MAIN;
}
interface DomainState {
    currentDomain: SiteDomain;
}

const initialState: DomainState = {
    currentDomain: initialDomain,
};

const domainSlice = createSlice({
    name: 'domain',
    initialState,
    reducers: {},
});

export default domainSlice.reducer;
