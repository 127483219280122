import { Route, Routes } from "react-router-dom";
import { ContentDetail } from "./Detail";
import { ContentEpisode } from "./Episode";

export const Content = () => {
    return (
        <Routes>
            <Route path="/:id" element={<ContentDetail />} />
            <Route path="/episode/:id" element={<ContentEpisode />} />
        </Routes>
    );
};
