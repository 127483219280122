import { Route, Routes } from "react-router-dom";
import { Favorite } from "./Favorite";
import { Recent } from "./Recent";
import { Purchased } from "./Purchased";
import { Category } from "./Category";

export const List = () => {
    return (
        <Routes>
            <Route path="/favorite" element={<Favorite />} />
            <Route path="/recent" element={<Recent />} />
            <Route path="/purchased" element={<Purchased />} />
            <Route path="/category/:category" element={<Category />} />
        </Routes>
    );
};
