import { useGetFavoriteContentQuery, useGetRecentContentQuery, useLazyGetContentDetailQuery, useLazyGetEpisodeListQuery, useLazyGetFavoriteStatusQuery, useSetFavoriteContentMutation } from "@src/redux/servicesNew/content";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Header } from "@components/Header";
import { formatGenre } from "@src/utils/formatGenre";
import { getAge } from "@src/utils/getAge";
import { UserIcon } from "@heroicons/react/16/solid";
import { ChevronDownIcon, ChevronUpIcon, StarIcon } from "@heroicons/react/20/solid";
import { HeartIcon } from "@heroicons/react/24/outline";
import { formatView } from "@src/utils/formatView";
import { formatScore } from "@src/utils/formatScore";
import { getStatus } from "@src/utils/getStatus";
import { Footer } from "@components/Footer";
import { ContentStatus, ContentTypes, OrderOptions, SortOptions } from "@src/redux/enums/content";
import { formatDate } from "@src/utils/formatDate";
import NavigateIcon from "@components/icons/NavigateIcon";
import { Spinner } from "@components/Spinner";
import { NoData } from "@components/NoData";
import { GetEpisodeListItem } from "@src/redux/interfaces/content/getEpisodeList";
import { PurchaseModal } from "@components/content/PurchaseModal";
import { IsError } from "@components/IsError";
import { GetFavoriteContentItem } from "@src/redux/interfaces/content/getFavorite";
import { IsExpired } from "@src/utils/isExpired";
import { useStorage } from "@hooks/useStorage";
import { formatDays } from "@src/utils/formatDays";
import { GetRecentContentItem } from "@src/redux/interfaces/content/getRecent";
import { splitDescription } from "@src/utils/splitDescription";

const count = 3; // 최근 본 작품, 관심 작품 표시 개수
const limit = 14; // 에피소드 표시 개수

export const ContentDetail = () => {
    const { id } = useParams();
    const [params] = useSearchParams();
    const type = Number(params.get('type'));
    const navigate = useNavigate();
    const { isLoggedIn } = useStorage();

    const [order, setOrder] = useState<OrderOptions>(OrderOptions.ASC);
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [selectedEpisode, setSelectedEpisode] = useState<GetEpisodeListItem>();
    const [descriptionOpen, setDescriptionOepn] = useState(false);
    const [description, setDescription] = useState<string[]>();

    const [cdTrigger, { data: cdData, error: cdError, isFetching: cdIsFetching }] = useLazyGetContentDetailQuery();
    const [fsTrigger, { data: fsData, error: fsError, isFetching: fsIsFetching }] = useLazyGetFavoriteStatusQuery();
    const [eTrigger, { data: eData, error: eError, isFetching: eIsFetching }] = useLazyGetEpisodeListQuery();
    const { data: rcData, error: rcError, isFetching: rcIsFetching } = useGetRecentContentQuery({ page: 1, count, sort: SortOptions.UPDATED_AT }, { skip: !isLoggedIn, refetchOnMountOrArgChange: true });
    const { data: fcData, error: fcError, isFetching: fcIsFetching } = useGetFavoriteContentQuery({ page: 1, count, IsValid: true }, { skip: !isLoggedIn });
    const [setFavoriteContent, { isSuccess: sfIsSuccess, error: sfError, isLoading: sfIsLoading }] = useSetFavoriteContentMutation();

    useEffect(() => {
        if (!id || type == null) {
            return;
        }
        cdTrigger({
            id, type
        }, true);
        fsTrigger({
            id: Number(id), type
        }, true);
    }, [id, type]);

    useEffect(() => {
        if (!id || type == null) {
            return;
        }
        eTrigger({
            id, type, page, limit, sort: order
        }, true);
    }, [id, type, page, order]);

    useEffect(() => {
        if (!cdData) {
            return;
        }
        setDescription(splitDescription(cdData.data.data.description));
    }, [cdData]);

    useEffect(() => {
        if (!eData) {
            return;
        }
        const total = eData.data.data.total;
        const limit = eData.data.data.limit;
        setMaxPage(Math.ceil(total / limit));
    }, [eData]);

    const movePage = (arg: number) => {
        if (arg >= maxPage || arg < 0) {
            return;
        }
        setPage(arg);
    }

    const setFavorite = async () => {
        if (!isLoggedIn) {
            navigate('/login');
        }
        await setFavoriteContent({
            type,
            WebtoonID: type === ContentTypes.WEBTOON ? Number(id) : undefined,
            NovelID: type === ContentTypes.WEBNOVEL ? Number(id) : undefined,
        });
    }

    const viewEpisode = (episode: GetEpisodeListItem) => {
        if (!isLoggedIn) {
            return navigate('/login');
        }
        if (episode.isFree || episode.isPurchased) {
            return navigate(`/content/episode/${episode.id}?type=${type}`);
        }
        setSelectedEpisode(episode);
    }

    const viewRecentContent = (content: GetRecentContentItem) => {
        return navigate(`/content/${content.ContentID}?type=${content.ContentType}`);
    }

    const viewFavoriteContent = (content: GetFavoriteContentItem) => {
        return navigate(`/content/${content.WebtoonID || content.NovelID}?type=${content.ContentType}`);
    }

    const closeModal = () => {
        setSelectedEpisode(undefined);
    }

    return (
        <div>
            {/* 구매 모달 */}
            {selectedEpisode &&
                <PurchaseModal episode={selectedEpisode} type={type} onClose={closeModal} />
            }
            <Header />
            <div className="
            grid max-w-[1200px] mx-auto font-notokr
            grid-cols-1 grid-rows-none p-4 gap-5
            md:p-10
            lg:grid-cols-[2fr_3fr] lg:grid-rows-[auto_auto_auto_1fr] lg:gap-7
            xl:px-0 xl:py-20 xl:gap-10
            ">
                {/* 컨텐츠 상세 정보 */}
                <div className="">
                    {cdIsFetching ?
                        <div className="flex justify-center items-center h-[50vh]">
                            <Spinner />
                        </div>
                        : cdData && description ?
                            <>
                                <div className="relative">
                                    <img src={cdData.data.data.image} className="absolute w-full h-full object-cover filter blur-md" />
                                    <img src={cdData.data.data.image} className="w-full h-full relative p-10 md:p-20" />
                                </div>
                                <div className="flex flex-col rounded-2xl border-alco-mint text-sm border-2 mt-4 p-4 gap-1">
                                    <div className="flex justify-center pt-3 md:pt-8">
                                        <span className="text-2xl font-semibold">{cdData.data.data.name}</span>
                                    </div>
                                    <div className="flex justify-center gap-1.5">
                                        <span className="">{formatGenre(cdData.data.data.genres)}</span>
                                        <span>•</span>
                                        <span className="">{getAge(cdData.data.data.age)}</span>
                                        <span>•</span>
                                        <span className="">{cdData.data.data.status === ContentStatus.SERIALIZING ? `${formatDays(cdData.data.data.days)} 연재` : getStatus(cdData.data.data.status)}</span>
                                    </div>
                                    <div className="flex justify-center gap-1.5 leading-[25px]">
                                        <div className="inline-flex items-center gap-0.5 text-gray-500">
                                            <UserIcon className="w-4 h-4" />
                                            <span className="">{formatView(cdData.data.data.total_views)}</span>
                                        </div>
                                        <div className="inline-flex items-center gap-0.5 text-red-500">
                                            <StarIcon className="w-4 h-4" />
                                            <span className="">{formatScore(cdData.data.data.score)}</span>
                                        </div>
                                    </div>
                                    <div className="flex items-start text-gray-500 py-3" onClick={() => description[1].length > 0 && setDescriptionOepn(!descriptionOpen)}>
                                        <div className={`flex-1 whitespace-pre-line ${!descriptionOpen && "line-clamp-1"}`}>
                                            <p>{description[0]}</p>
                                            {descriptionOpen && <>
                                                <p>{description[1]}</p>
                                            </>}
                                        </div>
                                        {description[1].length > 0 &&
                                            <div className="cursor-pointer w-5 h-5">
                                                {descriptionOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                            </div>}
                                    </div>
                                    <div className="flex py-1 gap-2 leading-[33px]">
                                        {fsIsFetching && !fsData ?
                                            <div className="flex justify-center items-center h-[30vh]">
                                                <Spinner />
                                            </div>
                                            : fsData ?
                                                <button
                                                    className={`
                                                        w-full flex items-center justify-center gap-1.5 font-bold rounded-xl 
                                                        border-2 border-alco-mint transition-all duration-75 
                                                        p-1.5 md:p-2.5 
                                                        ${fsData.data.data.isValid ? "text-alco-mint" : "bg-alco-mint text-white"} 
                                                        ${(sfIsLoading || fsIsFetching) && "opacity-20 pointer-events-none"}
                                                        `}
                                                    onClick={setFavorite}>
                                                    <HeartIcon className="w-7 h-7" fill={fsData.data.data.isValid ? "currentColor" : "none"} />
                                                    <span>관심</span>
                                                    <span>{fsData.data.data.totalRg.toLocaleString()}</span>
                                                </button>
                                                : fsError ?
                                                    <IsError /> : <NoData />
                                        }
                                        <button
                                            className="w-full bg-gray-300 font-bold rounded-2xl p-2 md:p-3"
                                            onClick={() => eData && viewEpisode(eData.data.data.list[0])}
                                        >
                                            첫 화 보기
                                        </button>
                                    </div>
                                </div>
                            </>
                            : cdError ?
                                <IsError /> : <NoData />
                    }
                </div>
                {/* 에피소드 목록 */}
                <div className="row-span-4 w-full">
                    {eIsFetching && !eData ?
                        <div className="flex-1 flex justify-center items-center h-[70vh]">
                            <Spinner />
                        </div> : eData ?
                            <div className="flex flex-col gap-4 relative">
                                <div className="flex self-end divide-x-[0.15rem] divide-gray-200 whitespace-nowrap text-sm md:text-base">
                                    <button
                                        className={`w-full pr-2 ${order === OrderOptions.ASC ? "text-alco-mint" : "text-gray-400"}`}
                                        onClick={() => setOrder(OrderOptions.ASC)}
                                    >
                                        <span className="leading-normal">첫화부터</span>
                                    </button>
                                    <button
                                        className={`w-full pl-2 ${order === OrderOptions.DESC ? "text-alco-mint" : "text-gray-400"}`}
                                        onClick={() => setOrder(OrderOptions.DESC)}
                                    >
                                        <span className="leading-normal">최신화부터</span>
                                    </button>
                                </div>
                                <div className={`flex flex-col gap-4 transition-opacity ${eIsFetching && "opacity-20"}`}>
                                    {eData.data.data.list.map((episode, index) =>
                                        <div
                                            className="flex cursor-pointer items-center gap-5"
                                            key={index}
                                            onClick={() => viewEpisode(episode)}
                                        >
                                            <div className="flex-[2.7] md:flex-[1.7]">
                                                <img className="rounded-2xl" src={episode.thumbnail} />
                                            </div>
                                            <div className="flex flex-col flex-[7.3] md:flex-[8.3]">
                                                <span className="font-medium md:text-xl">{episode.name}</span>
                                                <div className="flex gap-3 leading-[25px] text-sm md:text-base">
                                                    <div className="inline-flex items-center gap-0.5 text-red-500">
                                                        <StarIcon className="w-4 h-4" />
                                                        <span>{formatScore(episode.score)}</span>
                                                    </div>
                                                    <span>{formatDate(episode.validFrom)}</span>
                                                </div>
                                            </div>
                                            <div className="text-center flex-[1.8] md:flex-[1.4]">
                                                <span className={`font-medium whitespace-nowrap md:text-xl 
                                                ${episode.isPurchased &&
                                                    (IsExpired(episode.expiredAt) ? "text-red-500" : "text-alco-mint")}`}>
                                                    {
                                                        episode.isFree ? "무료"
                                                            : episode.isPurchased ?
                                                                episode.expiredAt > 0 ?
                                                                    IsExpired(episode.expiredAt) ?
                                                                        "만료됨" : "대여중"
                                                                    : "소장중"
                                                                : "구매 전"
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            : eError ?
                                <IsError /> : <NoData />
                    }
                    <div className="flex justify-center">
                        <div className="inline-flex justify-center items-center gap-2 text-gray-300 text-lg md:text-3xl">
                            <NavigateIcon direction="prev" className={`cursor-pointer mr-1 w-6 h-6 md:w-8 md:h-8 ${page === 0 && "opacity-50 pointer-events-none"}`} onClick={() => movePage(page - 1)} />
                            <span className="font-bold text-black">{page + 1}</span>
                            <span>/</span>
                            <span>{maxPage}</span>
                            <NavigateIcon direction="next" className={`cursor-pointer ml-1 w-6 h-6 md:w-8 md:h-8 ${page === maxPage - 1 && "opacity-50 pointer-events-none"}`} onClick={() => movePage(page + 1)} />
                        </div>
                    </div>
                </div>
                {/* 최근에 본 작품 */}
                <div className="">
                    {isLoggedIn &&
                        (rcIsFetching ?
                            <div className="flex justify-center items-center h-[30vh]">
                                <Spinner />
                            </div> : rcData ?
                                <div className="flex flex-col gap-4 p-4 bg-gray-200 rounded-2xl">
                                    <div className="flex justify-between">
                                        <span>최근에 본 작품</span>
                                        <button className="text-sm" onClick={() => navigate('/list/recent')}>더보기</button>
                                    </div>
                                    <div className="flex gap-4">
                                        {rcData.data.data.list.map((recent, index) =>
                                            <div className="flex-1 flex flex-col cursor-pointer text-xs gap-1.5" key={index} onClick={() => viewRecentContent(recent)}>
                                                <div className="">
                                                    <img className="rounded-2xl" src={recent.episode.thumbnail_image} />
                                                </div>
                                                <div className="">
                                                    <span>{recent.episode.name}</span>
                                                </div>
                                                <div className="text-[0.6rem]">
                                                    <span>{recent.ContentType === ContentTypes.WEBTOON ? '웹툰' : '웹소설'}</span>
                                                    <span className="px-1">•</span>
                                                    <span>{formatGenre(recent.episode.genres)}</span>
                                                </div>
                                            </div>
                                        )}
                                        {rcData.data.data.list.length < count
                                            ? Array.from({ length: count - rcData.data.data.list.length }).map((_, i) => (
                                                <div key={i} className="flex-1"></div>
                                            ))
                                            : null
                                        }
                                    </div>
                                </div>
                                : rcError &&
                                <IsError />)
                    }
                </div>
                {/* 관심 작품*/}
                <div className="">
                    {isLoggedIn &&
                        (fcIsFetching && !fcData ?
                            <div className="flex justify-center items-center h-[30vh]">
                                <Spinner />
                            </div> : fcData ?
                                <div className="flex flex-col gap-4 p-4 bg-gray-200 rounded-2xl">
                                    <div className="flex justify-between">
                                        <span>관심 작품</span>
                                        <button className="text-sm" onClick={() => navigate('/list/favorite')}>더보기</button>
                                    </div>
                                    <div className={`flex gap-4 transition-opacity duration-75 ${fcIsFetching && "opacity-20 pointer-events-none"}`}>
                                        {fcData.data.data.lists.map((favorite, index) =>
                                            <div className="flex-1 flex flex-col cursor-pointer text-xs gap-1.5" key={index} onClick={() => viewFavoriteContent(favorite)}>
                                                <div className="">
                                                    <img className="rounded-2xl" src={favorite.webtoon?.thumbnail_image || favorite.novel?.thumbnail_image} />
                                                </div>
                                                <div className="">
                                                    <span>{favorite.webtoon?.webtoon_name || favorite.novel?.novel_name}</span>
                                                </div>
                                                <div className="text-[0.6rem]">
                                                    <span>{favorite.ContentType === ContentTypes.WEBTOON ? '웹툰' : '웹소설'}</span>
                                                    <span className="px-1">•</span>
                                                    <span>{formatGenre(favorite.webtoon?.genres || favorite.novel?.genres)}</span>
                                                </div>
                                            </div>
                                        )}
                                        {fcData.data.data.lists.length < count
                                            ? Array.from({ length: count - fcData.data.data.lists.length }).map((_, i) => (
                                                <div key={i} className="flex-1"></div>
                                            ))
                                            : null
                                        }
                                    </div>
                                </div>
                                : fcError &&
                                <IsError />)
                    }
                </div>
                {/* grid 높이 보정을 위한 빈 객체 */}
                <div className="">
                </div>
            </div>
            <Footer />
        </div>
    );
}