import React from "react";

interface SearchIconProps {
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  onClick?: () => void;
  className?: string;
}

const SearchIcon: React.FC<SearchIconProps> = ({ fill, stroke, strokeWidth, onClick, className }) => {

  return (
    <svg
      className={className}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill ?? "none"}
      stroke={stroke}
      strokeWidth={strokeWidth}
      viewBox="0 0 20 20"
      onClick={onClick}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
      />
    </svg>
  );
};

export default SearchIcon;
