import { formatGenre } from "@src/utils/formatGenre";
import { useNavigate } from "react-router-dom";

export const RankingWebnovelContent = ({
  image,
  title,
  tags,
  id,
  idx,
  isSmall,
  isBottom,
  badge,
}: {
  image: any;
  title: string;
  tags: any;
  id: any;
  idx?: number;
  isSmall?: boolean;
  isBottom?: boolean;
  badge?: boolean;
}) => {
  const navigate = useNavigate();
  // console.log(idx);
  return (
    <div className={`relative ${isBottom ? "mb-5" : ""} max-lg:mb-0`}>
      {badge && idx && (
        <div className="absolute top-[-1rem] left-[-1.5rem] flex justify-center items-center w-10 h-10 text-xl md:w-14 md:h-14 md:text-2xl text-center text-white font-notokr bg-opacity-[65%] bg-[#EAB021] rounded-[20px] font-bold max-header:left-[-0.5rem]">
          <span className="">{idx}</span>
        </div>
      )}
      <div className={`w-full h-full ${isSmall && "p-1"}`}>
        <div
          className="col-span-1 w-full h-full bg-white"
          style={{
            borderRadius: "20px",
          }}
          onClick={() => {
            navigate(`/content/${id}?type=1`);
          }}
        >
          <img
            className="w-full"
            src={image}
            style={{ borderRadius: "20px 20px 0 0" }}
          />
          <div
            className="pl-2 text-left mt-3 text-sm truncate text-md mb-2"
            style={{ fontFamily: "Noto Sans KR" }}
          >
            {title}
          </div>
          <div className="flex flex-row pl-2 mb-3 gap-1 overflow-auto hide-scroll">
            <span className="text-xs md:text-sm text-gray-500">{formatGenre(tags)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
