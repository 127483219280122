export enum SortOptions {
    UPDATED_AT = 'updated_at',
    TOTAL_VIEWS = 'total_views',
    EPISODES = 'episodes',
    NAME = 'name',
}

export enum OrderOptions {
    ASC = 'asc',
    DESC = 'desc',
}

export enum PurchaseTypes {
    COLLECTION,
    RENTAL,
}

export enum ContentTypes {
    WEBTOON,
    WEBNOVEL,
}

export enum ContentCategory {
    POPULAR = 'popular',
    CHALLENGE = 'challenge',
    TODAY_FREE = 'today-free',
    NEW = 'new',
}

export enum ContentStatus{
    SERIALIZING = 1,
    COMPLETED = 2,
    HIATUS = 3,
}

export const validateOption = <T extends { [key: string]: unknown }>(value: any, enumObj: T, defaultValue: T[keyof T]): T[keyof T] => {
    return Object.values(enumObj).includes(value) ? value : defaultValue;
}