import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { HeartIcon, HomeIcon, StarIcon } from "@heroicons/react/24/outline";
import { formatScore } from "@src/utils/formatScore";
import NavigateIcon from "@components/icons/NavigateIcon";
import { ImageLoader } from "@components/content/ImageLoader";
import { useLazyGetEpisodeScoreQuery, useLazyGetFavoriteStatusQuery, useLazyGetEpisodeCutQuery, useSetFavoriteContentMutation } from "@src/redux/servicesNew/content";
import { Spinner } from "@components/Spinner";
import { NoData } from "@components/NoData";
import { IsError } from "@components/IsError";
import { useEffect, useState } from "react";
import { ContentTypes } from "@src/redux/enums/content";
import { EpubLoader } from "@components/content/EpubLoader";
import { RatingModal } from "@components/content/RatingModal";
import { PurchaseModal } from "@components/content/PurchaseModal";
import { useStorage } from "@hooks/useStorage";

export const ContentEpisode = () => {
    const { id } = useParams();
    const [params] = useSearchParams();
    const { isLoggedIn } = useStorage();
    const type = Number(params.get('type'));
    const navigate = useNavigate();

    const [ratingModalOpen, setRatingModalOpen] = useState(false);
    const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);

    const [ecTrigger, { data: ecData, error: ecError, isFetching: ecIsFetching }] = useLazyGetEpisodeCutQuery();
    const [seTrigger, { data: seData, error: seError, isFetching: seIsFetching }] = useLazyGetEpisodeCutQuery();
    const [esTrigger, { data: esData, error: esError, isFetching: esIsFetching, isUninitialized: esIsUninitialized }] = useLazyGetEpisodeScoreQuery();
    const [fsTrigger, { data: fsData, error: fsError, isFetching: fsIsFetching, isUninitialized: fsIsUninitialized }] = useLazyGetFavoriteStatusQuery();
    const [setFavoriteContent, { isSuccess: sfIsSuccess, error: sfError, isLoading: sfIsLoading }] = useSetFavoriteContentMutation();

    useEffect(() => {
        if (!id) {
            return;
        }
        ecTrigger({
            episode_id: id,
            type,
        }, true);
        esTrigger({
            EpisodeId: id,
            type,
        }, true);
    }, [id]);

    useEffect(() => {
        if (!id || !ecData) {
            return;
        }
        fsTrigger({
            id: Number(ecData.data.data.id), type
        }, true);
    }, [ecData]);

    const setFavorite = async () => {
        if (!isLoggedIn) {
            return navigate('/login');
        }
        if (ecData) {
            await setFavoriteContent({
                type,
                WebtoonID: type === ContentTypes.WEBTOON ? Number(ecData.data.data.id) : undefined,
                NovelID: type === ContentTypes.WEBNOVEL ? Number(ecData.data.data.id) : undefined,
            });
        }
    }

    const viewContent = () => {
        if (!ecData) {
            return;
        }
        navigate(`/content/${ecData.data.data.id}?type=${type}`);
    }

    const moveEpisode = async (target: 'prev' | 'next') => {
        if (!ecData) {
            return;
        }
        const episode = await seTrigger({
            episode_id: target === 'prev' ? ecData.data.data.prevId : ecData.data.data.nextId,
            type,
        }, true).unwrap();
        // Todo: 예외 처리
        if (episode.data.data.isFree || episode.data.data.isPurchased) {
            setPurchaseModalOpen(false);
            return navigate(`/content/episode/${episode.data.data.titleID}?type=${type}`);
        }
        else {
            setPurchaseModalOpen(true);
        }
    }

    const openRating = () => {
        if (!isLoggedIn) {
            return navigate('/login');
        }
        setRatingModalOpen(true);
    }

    return (
        <div className="flex flex-col font-notokr text-white h-[100dvh]">
            {/* 구매 모달 */}
            {seIsFetching ?
                <div className="w-screen h-screen z-30 fixed flex justify-center items-center bg-black/20">
                    <Spinner />
                </div> :
                purchaseModalOpen && seData &&
                <PurchaseModal episode={seData.data.data} type={type} onClose={() => setPurchaseModalOpen(false)} />
            }
            {/* 별점 모달 */}
            {ratingModalOpen && ecData && esData &&
                <RatingModal
                    cut={ecData.data.data}
                    type={type}
                    onClose={() => setRatingModalOpen(false)}
                    score={esData.data.data.UserPrevScore} />
            }
            {/* 상단바 */}
            <div className="pb-16">
                <div className="absolute flex justify-center h-16 w-full z-20 bg-black">
                    <div className={`flex items-center w-full px-4 ${type === ContentTypes.WEBTOON ? "max-w-[720px]" : "max-w-[1200px]"}`}>
                        <div className="flex items-center">
                            <img
                                src="/logocolor.png"
                                alt="logo"
                                className="w-12"
                                onClick={() => navigate('/')}
                            />
                        </div>
                        <div className="flex-1 flex items-center justify-center">
                            <span className="font-semibold">{ecData && ecData.data.data.name}</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* 컨텐츠 */}
            <div className="flex-1 flex flex-col w-full self-center">
                {ecIsFetching ?
                    <div className="flex justify-center">
                        <Spinner />
                    </div>
                    : ecData ?
                        type === ContentTypes.WEBTOON ?
                            ecData.data.data.cuts.map((cut, index) =>
                                <ImageLoader src={cut} key={index} alt={`cut-${index}`} />
                            )
                            : <EpubLoader src={ecData.data.data.cuts[0]} />
                        : ecError ?
                            <IsError /> : <NoData />}
            </div>
            {/* 하단바 */}
            <div className="pt-16">
                <div className="fixed bottom-0 flex justify-center h-16 w-full bg-black z-20">
                    <div className={`flex justify-between items-center w-full px-4 ${type === ContentTypes.WEBTOON ? "max-w-[720px]" : "max-w-[1200px]"}`}>
                        <div className="flex items-center gap-5">
                            {!fsData || fsIsUninitialized ?
                                <div className="flex justify-center">
                                    <Spinner />
                                </div>
                                : fsData ?
                                    <div
                                        className={`flex items-center cursor-pointer gap-1 ${(sfIsLoading || fsIsFetching) && "opacity-30 pointer-events-none"}`}
                                        onClick={setFavorite}>
                                        <HeartIcon
                                            className="text-alco-mint w-8 h-8" fill={fsData.data.data.isValid ? "currentColor" : "none"}
                                        />
                                        <div className="flex flex-col items-center text-center text-xs font-medium">
                                            <span>관심</span>
                                            <span>{fsData.data.data.totalRg.toLocaleString()}</span>
                                        </div>
                                    </div>
                                    : fsError ?
                                        <IsError /> : <NoData />
                            }
                            {!esData || esIsUninitialized ?
                                <div className="flex justify-center">
                                    <Spinner />
                                </div>
                                : esData ?
                                    <div
                                        className={`flex items-center cursor-pointer gap-1 ${(esIsFetching) && "opacity-30 pointer-events-none"}`}
                                        onClick={openRating}>
                                        <StarIcon className="text-red-500 w-8 h-8" fill={esData.data.data.valid ? "currentColor" : "none"} />
                                        <div className="flex flex-col items-center text-center text-xs font-medium">
                                            <span>{esData.data.data.valid ? "별점" : "별점주기"}</span>
                                            <span>{formatScore(esData.data.data.UserPrevScore)}</span>
                                        </div>
                                    </div>
                                    : esError ?
                                        <IsError /> : <NoData />
                            }
                        </div>
                        <div className="flex items-center gap-5">
                            <NavigateIcon
                                direction="prev"
                                className={`cursor-pointer w-6 h-6 ${(!ecData || ecIsFetching || ecData.data.data.prevId.length === 0) && "opacity-50 pointer-events-none"}`}
                                onClick={() => moveEpisode('prev')} />
                            <HomeIcon className="cursor-pointer w-8 h-8" onClick={viewContent} />
                            <NavigateIcon
                                direction="next"
                                className={`cursor-pointer w-6 h-6 ${(!ecData || ecIsFetching || ecData.data.data.nextId.length === 0) && "opacity-50 pointer-events-none"}`}
                                onClick={() => moveEpisode('next')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}