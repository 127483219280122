import { ApiResponse } from "../interfaces/api";
import { GetBannerReq, GetBannerRes } from "../interfaces/banner/getBanner";
import { allComicsApi } from "./api";

export const bannerApi = allComicsApi.injectEndpoints({
    endpoints: (builder) => ({
        /** 배너 불러오기 */
        getBanner: builder.query<ApiResponse<GetBannerRes>, GetBannerReq>({
            query: (req) => ({
                url: '/v1/client/banner/search',
                method: "POST",
                body: req,
            }),
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
        }),
    })
});

export const {
    useGetBannerQuery,
} = bannerApi;