import { RootState } from "@store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useStorage = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { userId } = useSelector((state: RootState) => state.storage.session.globalUserSlice);

    useEffect(() => {
        if (!userId) {
            return;
        }
        return setIsLoggedIn(userId !== -1);
    }, [userId]);

    return {
        isLoggedIn
    };
}