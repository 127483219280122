import { Footer } from "@components/Footer";
import { Header } from "@components/Header";
import { IsError } from "@components/IsError";
import { NoData } from "@components/NoData";
import { SearchForm } from "@components/SearchForm";
import { Spinner } from "@components/Spinner";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { ContentTypes, SortOptions } from "@src/redux/enums/content";
import { GetFavoriteContentItem } from "@src/redux/interfaces/content/getFavorite";
import { useLazyGetFavoriteContentQuery } from "@src/redux/servicesNew/content";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Favorite = () => {
    const [sort, setSort] = useState<SortOptions>(SortOptions.UPDATED_AT);
    const [page, setPage] = useState<number>(1);
    const [maxPage, setMaxPage] = useState(0);
    const [contentType, setContentType] = useState<ContentTypes>(ContentTypes.WEBTOON);
    const [inputVal, setInputVal] = useState<string>('');
    const [keyword, setKeyword] = useState<string>('');
    const [trigger, { data, error, isFetching }] = useLazyGetFavoriteContentQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if (!sort || !page || contentType === null) {
            return;
        }
        trigger({
            page, sort, count: 10, IsValid: true, ContentType: contentType, keyword,
        }, true);
    }, [sort, page, contentType, keyword]);

    useEffect(() => {
        if (!data) {
            return;
        }
        const total = data.data.data.total;
        const count = data.data.data.count;
        setMaxPage(Math.ceil(total / count));
    }, [data]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setKeyword(inputVal);
    }

    const handleClear = () => {
        setInputVal('');
        setKeyword('');
    }

    const toggleType = (type: ContentTypes) => {
        setContentType(type);
        setPage(1);
        setInputVal('');
        setKeyword('');
    }

    const movePage = (arg: number) => {
        if (arg > maxPage || arg < 0) {
            return;
        }
        setPage(arg);
    }

    const viewEpisode = (content: GetFavoriteContentItem) => {
        navigate(`/content/episode/${content.WebtoonID || content.NovelID}_E001?type=${contentType}`);
    }

    const viewContent = (content: GetFavoriteContentItem) => {
        navigate(`/content/${content.WebtoonID || content.NovelID}?type=${contentType}`);
    }

    return (
        <div className="w-full h-full flex flex-col">
            <Header />
            <div className="flex flex-col w-full max-w-[1200px] mx-auto font-notokr gap-4 px-4 pt-4 md:pt-14 pb-20">
                {/* 리스트 제목 */}
                <div className="flex items-center gap-4">
                    <img className="w-8 h-8 md:w-12 md:h-12" src="/books.png" />
                    <span className="md:text-xl font-bold">관심 작품</span>
                </div>
                {/* 리스트 옵션 */}
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2">
                    <div className="flex-[2] text-sm md:text-base font-medium">
                        <div className="flex divide-x-[0.15rem] divide-gray-200">
                            <div className="flex gap-2 pr-2">
                                <button
                                    onClick={() => setSort(SortOptions.UPDATED_AT)}
                                    className={`${sort == SortOptions.UPDATED_AT ? "text-alco-mint" : "text-gray-400"}`}
                                >
                                    <span className="leading-normal">최신 등록순</span>
                                </button>
                                <button
                                    onClick={() => setSort(SortOptions.NAME)}
                                    className={`${sort == SortOptions.NAME ? "text-alco-mint" : "text-gray-400"}`}
                                >
                                    <span className="leading-normal">제목순</span>
                                </button>
                            </div>
                            <div className="flex gap-2 pl-2">
                                <button
                                    onClick={() => toggleType(ContentTypes.WEBTOON)}
                                    className={`${contentType == ContentTypes.WEBTOON ? "text-alco-mint" : "text-gray-400"}`}
                                >
                                    <span className="leading-normal">웹툰</span>
                                </button>
                                <button
                                    onClick={() => toggleType(ContentTypes.WEBNOVEL)}
                                    className={`${contentType == ContentTypes.WEBNOVEL ? "text-alco-mint" : "text-gray-400"}`}
                                >
                                    <span className="leading-normal">웹소설</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-[1] flex w-full items-center py-1">
                        <SearchForm inputVal={inputVal} onChange={(event) => setInputVal(event.target.value)} onSubmit={handleSubmit} onClear={handleClear} />
                    </div>
                </div>
                {/* 작품 리스트 */}
                {isFetching && !data ?
                    <div className="flex justify-center items-center h-[30vh]">
                        <Spinner />
                    </div>
                    : data ?
                        <div className={`flex flex-col gap-4 ${isFetching && "opacity-50 pointer-events-none"}`}>
                            <div className="grid grid-cols-1 md:grid-cols-2">
                                {data.data.data.lists.map((item, index) => (
                                    <div className="flex items-center border-b-2 gap-4 lg:gap-8 p-4" key={index}>
                                        <div className="flex-[1.8]">
                                            <img className="rounded-2xl drop-shadow-xl" src={item.webtoon?.thumbnail_image || item.novel?.thumbnail_image} />
                                        </div>
                                        <div className="flex-[4] flex flex-col justify-center items-start gap-4">
                                            <span className="lg:text-xl font-medium">{item.webtoon?.webtoon_name || item.novel?.novel_name}</span>
                                            <button
                                                className="text-sm md:text-base px-2 py-1 md:px-4 md:py-2 text-white bg-alco-mint rounded-lg"
                                                onClick={() => viewEpisode(item)}>
                                                첫화보기
                                            </button>
                                        </div>
                                        <div className="flex-[1]">
                                            <button onClick={() => viewContent(item)}>
                                                <Bars3Icon
                                                    className="w-8 h-8 lg:w-12 lg:h-12" />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-end gap-4">
                                <button
                                    className={`bg-gray-100 text-gray-400 border-gray-400 border-2 rounded-lg ${page === 1 && "opacity-50 pointer-events-none"}`}
                                    onClick={() => movePage(page - 1)}>
                                    <ChevronLeftIcon className="w-8 h-8" />
                                </button>
                                <button
                                    className={`bg-gray-100 text-gray-400 border-gray-400 border-2 rounded-lg ${page >= maxPage && "opacity-50 pointer-events-none"}`}
                                    onClick={() => movePage(page + 1)}
                                >
                                    <ChevronRightIcon className="w-8 h-8" />
                                </button>
                            </div>
                        </div>
                        : error ? <IsError /> : <NoData />
                }

            </div>
            <Footer />
        </div>
    );
}