import { XMarkIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

export const PurchaseFailedModal = ({ onClose }: { onClose: () => void }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        onClose();
        navigate('/payments');
    }

    return (
        <div className="w-screen h-screen fixed z-40 bg-black/20 font-notokr" onClick={onClose}>
            <div className="flex w-full h-full justify-center items-center px-4">
                <div className="flex flex-col bg-white text-black w-full overflow-hidden z-40 rounded-lg shadow-md max-w-[400px]" onClick={(e) => e.stopPropagation()}>
                    <div className="flex justify-between items-center bg-alco-mint text-white p-3">
                        <span className="font-semibold text-lg pl-3">알림</span>
                        <button onClick={onClose}>
                            <XMarkIcon className="w-8 h-8" />
                        </button>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-5 p-8">
                        <div className="">
                            <span className="">코인이 부족합니다.</span>
                        </div>

                        <div className="flex">
                            <button
                                className="bg-alco-mint text-white rounded-lg px-3 py-2"
                                onClick={handleClick}
                            >
                                <span>코인 충전하기</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}