import { Spinner } from "@components/Spinner";
import { useState } from "react";

export const ImageLoader = ({ src, alt }: { src: string; alt?: string }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    return (
        <div className="self-center flex justify-center relative w-full h-full max-w-[760px]">
            {loading &&
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100 h-screen">
                    <Spinner />
                </div>
            }
            <img
                src={src}
                alt={alt}
                onLoad={() => setLoading(false)}
                onError={() => setError(true)}
                className={`${loading ? "opacity-0" : "opacity-100"} transition-opacity duration-300`}
            />
            {error &&
                <div className="flex items-center justify-center h-10">
                    <span className="text-black">이미지를 불러오지 못하였습니다.</span>
                </div>
            }
        </div>
    );
};