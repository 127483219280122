import { ApiResponse } from "../interfaces/api";
import { SearchContentReq, SearchContentRes } from "../interfaces/content/searchContent";
import { GetContentDetailReq, GetContentDetailRes } from "../interfaces/content/getDetail";
import { GetPurchasedContentReq, GetPurchasedContentRes } from "../interfaces/content/getPurchased";
import { GetFavoriteContentReq, GetFavoriteContentRes } from "../interfaces/content/getFavorite";
import { GetEpisodeListReq, GetEpisodeListRes } from "../interfaces/content/getEpisodeList";
import { SetFavoriteContentReq, SetFavoriteContentRes } from "../interfaces/content/setFavorite";
import { GetEpisodeCutReq, GetEpisodeCutRes } from "../interfaces/content/getEpisodeCut";
import { GetEpisodeScoreReq, GetEpisodeScoreRes } from "../interfaces/content/getEpisodeScore";
import { PurchaseContentReq, PurchaseContentRes } from "../interfaces/content/purchaseContent";
import { ContentTypes } from "../enums/content";
import { GetFavoriteStatusReq, GetFavoriteStatusRes } from "../interfaces/content/getFavoriteStatus";
import { SetEpisodeScoreReq, SetEpisodeScoreRes } from "../interfaces/content/setEpisodeScore";
import { allComicsApi } from "./api";
import { GetRecentContentReq, GetRecentContentRes } from "../interfaces/content/getRecent";
import { GetCategoryContentReq, GetCategoryContentRes } from "../interfaces/content/getCategory";

export const contentApi = allComicsApi.injectEndpoints({
    endpoints: (builder) => ({
        /** 컨텐츠 검색 */
        searchContent: builder.query<ApiResponse<SearchContentRes>, SearchContentReq>({
            query: (req) => ({
                url: '/v1/client/total/search',
                method: "POST",
                body: req,
            }),
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
        }),
        /** 구매한 컨텐츠 목록 조회 */
        getPurchasedContent: builder.query<ApiResponse<GetPurchasedContentRes>, GetPurchasedContentReq>({
            query: (req) => ({
                url: '/v1/client/web-site/buy/all-search',
                method: "POST",
                body: req,
            }),
            providesTags: ['Purchase'],
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
        }),
        /** 컨텐츠 구매 */
        purchaseContent: builder.mutation<ApiResponse<PurchaseContentRes>, PurchaseContentReq>({
            query: (req) => ({
                url: '/v1/client/web-site/buy/register',
                method: "POST",
                body: req,
            }),
            invalidatesTags: ['Purchase'],
        }),
        /** 컨텐츠 세부 조회 */
        getContentDetail: builder.query<ApiResponse<GetContentDetailRes>, GetContentDetailReq>({
            query: (req) => ({
                url: `/v1/client/web-site/${req.type === ContentTypes.WEBTOON ? 'webtoon' : 'webnovel'}/detail`,
                method: "POST",
                body: req,
            }),
            providesTags: ['Score'],
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
        }),
        /** 관심 작품 목록 조회 */
        getFavoriteContent: builder.query<ApiResponse<GetFavoriteContentRes>, GetFavoriteContentReq>({
            query: (req) => ({
                url: "/v1/client/web-site/account/recommended/search",
                method: "POST",
                body: req,
            }),
            providesTags: ['Favorite'],
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
        }),
        /** 관심 작품 상태 조회 */
        getFavoriteStatus: builder.query<ApiResponse<GetFavoriteStatusRes>, GetFavoriteStatusReq>({
            query: (req) => ({
                url: `/v1/client/web-site/recommended/detail`,
                method: "POST",
                body: req,
            }),
            providesTags: ['Favorite'],
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
            transformResponse: (res: ApiResponse<GetFavoriteStatusRes>): ApiResponse<GetFavoriteStatusRes> => {
                const userId = JSON.parse(JSON.parse(sessionStorage.getItem('persist:alco-session') || '').globalUserSlice).userId;
                if (userId === -1) {
                    return { ...res, data: { ...res.data, data: { ...res.data.data, isValid: false } } };
                }
                else {
                    return res;
                }
            },
        }),
        /** 관심 작품 등록 및 해제 */
        setFavoriteContent: builder.mutation<ApiResponse<SetFavoriteContentRes>, SetFavoriteContentReq>({
            query: (req) => ({
                url: `/v1/client/web-site/${req.type === ContentTypes.WEBTOON ? 'webtoon' : 'novel'}/recommended/register`,
                method: "POST",
                body: req,
            }),
            invalidatesTags: ['Favorite'],
        }),
        /** 에피소드 목록 조회 */
        getEpisodeList: builder.query<ApiResponse<GetEpisodeListRes>, GetEpisodeListReq>({
            query: (req) => ({
                url: `/v1/client/web-site/${req.type === ContentTypes.WEBTOON ? 'webtoon' : 'webnovel'}/episode/search`,
                method: "POST",
                body: req,
            }),
            providesTags: ['Purchase', 'Score'],
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
            transformResponse: (res: ApiResponse<GetEpisodeListRes>): ApiResponse<GetEpisodeListRes> => {
                const userId = JSON.parse(JSON.parse(sessionStorage.getItem('persist:alco-session') || '').globalUserSlice).userId;
                if (userId === -1) {
                    return { ...res, data: { ...res.data, data: { ...res.data.data, list: res.data.data.list.map((item) => ({ ...item, isPurchased: false, })) }, }, };
                }
                else {
                    return res;
                }
            },
        }),
        /** 에피소드 별점 조회 */
        getEpisodeScore: builder.query<ApiResponse<GetEpisodeScoreRes>, GetEpisodeScoreReq>({
            query: (req) => ({
                url: `/v1/client/web-site/episode/score/search`,
                method: "POST",
                body: req,
            }),
            providesTags: ['Score'],
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
            transformResponse: (res: ApiResponse<GetEpisodeScoreRes>): ApiResponse<GetEpisodeScoreRes> => {
                const userId = JSON.parse(JSON.parse(sessionStorage.getItem('persist:alco-session') || '').globalUserSlice).userId;
                if (userId === -1) {
                    return { ...res, data: { ...res.data, data: { ...res.data.data, UserPrevScore: 0, valid: false } } }
                }
                else {
                    return res;
                }
            },
        }),
        /** 에피소드 별점 등록 */
        setEpisodeScore: builder.mutation<ApiResponse<SetEpisodeScoreRes>, SetEpisodeScoreReq>({
            query: (req) => ({
                url: `/v1/client/web-site/episode/score/register`,
                method: "POST",
                body: req,
            }),
            invalidatesTags: ['Score'],
        }),
        /** 에피소드 컷 조회 */
        getEpisodeCut: builder.query<ApiResponse<GetEpisodeCutRes>, GetEpisodeCutReq>({
            query: (req) => ({
                url: `/v1/client/web-site/${req.type === ContentTypes.WEBTOON ? 'webtoon' : 'webnovel'}/episode/cuts/search`,
                method: "POST",
                body: req,
            }),
            providesTags: ['Purchase'],
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
            transformResponse: (res: ApiResponse<GetEpisodeCutRes>): ApiResponse<GetEpisodeCutRes> => {
                const userId = JSON.parse(JSON.parse(sessionStorage.getItem('persist:alco-session') || '').globalUserSlice).userId;
                if (userId === -1) {
                    return { ...res, data: { ...res.data, data: { ...res.data.data, isPurchased: false } } }
                }
                else {
                    return res;
                }
            },
        }),
        /** 최근 본 작품 목록 조회 */
        getRecentContent: builder.query<ApiResponse<GetRecentContentRes>, GetRecentContentReq>({
            query: (req) => ({
                url: `/v1/client/web-site/account/recent-view/search`,
                method: "POST",
                body: req,
            }),
        }),
        /** 카테고리 작품 목록 조회 */
        getCategoryContent: builder.query<ApiResponse<GetCategoryContentRes>, GetCategoryContentReq>({
            query: (req) => ({
                url: `/v1/client/${req.type === ContentTypes.WEBTOON ? 'webtoon' : 'novel'}/${req.category}/search`,
                method: "POST",
                body: req,
            }),
        }),
    }),
});

export const {
    useLazySearchContentQuery,
    useGetPurchasedContentQuery,
    useLazyGetPurchasedContentQuery,
    usePurchaseContentMutation,
    useLazyGetContentDetailQuery,
    useGetFavoriteContentQuery,
    useLazyGetFavoriteContentQuery,
    useLazyGetFavoriteStatusQuery,
    useSetFavoriteContentMutation,
    useLazyGetEpisodeListQuery,
    useLazyGetEpisodeScoreQuery,
    useSetEpisodeScoreMutation,
    useLazyGetEpisodeCutQuery,
    useGetRecentContentQuery,
    useLazyGetRecentContentQuery,
    useLazyGetCategoryContentQuery,
} = contentApi;