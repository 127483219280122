import { Header } from "@components/Header";
import { ChangeEvent, FormEvent, useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLazySearchContentQuery } from "@src/redux/servicesNew/content";
import { useLanguage } from "@hooks/useLanguage";
import { Spinner } from "@components/Spinner";
import { NoData } from "@components/NoData";
import { SearchForm } from "@components/SearchForm";
import { OrderOptions, SortOptions, validateOption } from "@src/redux/enums/content";
import { formatGenre } from "@src/utils/formatGenre";
import { getStatus } from "@src/utils/getStatus";
import { UserIcon } from "@heroicons/react/16/solid";
import { StarIcon } from "@heroicons/react/20/solid";
import { formatView } from "@src/utils/formatView";
import { formatScore } from "@src/utils/formatScore";
import { SearchContentItem } from "@src/redux/interfaces/content/searchContent";

export const Search = () => {
  const [params, setParams] = useSearchParams();

  /* 파라미터 정제 */
  const keyword = params.get("keyword") || "";
  const limit = Number(params.get("limit")) || 10;
  const sort = validateOption(params.get("sort"), SortOptions, SortOptions.UPDATED_AT);
  const order = validateOption(params.get("order"), OrderOptions, OrderOptions.DESC);
  const type = params.get("type") == null ? undefined : Number(params.get("type"));

  const { currentLanguage } = useLanguage();
  const [trigger, { data, error, isFetching }] = useLazySearchContentQuery();
  const navigate = useNavigate();
  const [inputVal, setInputVal] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [results, setResults] = useState<SearchContentItem[]>([]); // 누적 검색 결과
  const observerRef = useRef<HTMLDivElement>(null); // Intersection Observer

  const fetchResults = (newPage: number) => {
    trigger(
      {
        keyword,
        page: newPage,
        limit,
        sort,
        order,
        type,
        language: currentLanguage,
      },
      true
    );
  };

  useEffect(() => {
    if (keyword && keyword.length > 0) {
      setInputVal(keyword);
      setPage(0);
      setResults([]);
      fetchResults(0);
    }
  }, [params]);

  // 검색 결과 merge
  useEffect(() => {
    if (data && data.data.data.list.length > 0) {
      setResults((prev) => [...prev, ...data.data.data.list]);
    }
  }, [data]);

  // Intersection Observer
  useEffect(() => {
    if (observerRef == null || !observerRef.current || results.length == 0) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isFetching) {
          if (data && results.length < data.data.data.total) {
            setPage((prev) => prev + 1);
          }
        }
      },
      { threshold: 1.0 }
    );
    observer.observe(observerRef.current);

    return () => {
      if (observerRef.current) {
        observer.disconnect();
      }
    };
  }, [results]);

  // 페이지가 증가할 때 API 호출
  useEffect(() => {
    if (page > 0) {
      fetchResults(page);
    }
  }, [page]);

  const moveType = (typeOption?: number) => {
    if (typeOption == undefined) {
      params.delete("type");
    } else {
      params.set("type", typeOption.toString());
    }
    setParams(params);
  };

  const setSort = (sortOption: SortOptions, orderOption: OrderOptions) => {
    if (sortOption == sort && orderOption == order) {
      return;
    } else {
      params.set("sort", sortOption);
      params.set("order", orderOption);
    }
    setParams(params);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputVal(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputVal.length > 0) {
      params.set("keyword", inputVal);
      setParams(params);
    }
  };

  const handleClear = () => {
    setInputVal("");
  };

  return (
    <div className="w-full h-full flex flex-col">
      <Header />
      <div className="w-full p-4 font-notokr flex-1 flex flex-col lg:max-w-[1200px] mx-auto">
        <div className="flex w-full items-center py-1">
          <SearchForm inputVal={inputVal} onSubmit={handleSubmit} onChange={handleChange} onClear={handleClear} />
        </div>
        <div className="flex items-center text-base font-medium py-1">
          <button
            className={`flex justify-center flex-1 py-2 border-b-2 ${type == null ? "border-alco-mint text-alco-mint" : ""}`}
            onClick={() => moveType()}
          >
            <span>전체</span>
          </button>
          <button
            className={`flex justify-center flex-1 py-2 border-b-2 ${type == 0 ? "border-alco-mint text-alco-mint" : ""}`}
            onClick={() => moveType(0)}
          >
            <span>웹툰</span>
          </button>
          <button
            className={`flex justify-center flex-1 py-2 border-b-2 ${type == 1 ? "border-alco-mint text-alco-mint" : ""}`}
            onClick={() => moveType(1)}
          >
            <span>웹소설</span>
          </button>
        </div>
        <div className="flex items-center justify-between w-full p-3">
          <div>
            <span className="text-sm text-gray-500">{data ? data.data.data.total : 0}개</span>
          </div>
          <div className="flex divide-x-[0.15rem] divide-gray-200 text-sm font-medium">
            <button
              onClick={() => setSort(SortOptions.UPDATED_AT, OrderOptions.DESC)}
              className={`w-full flex-1 pr-2 ${(!sort || sort == SortOptions.UPDATED_AT) ? "text-alco-mint" : "text-gray-400"}`}
            >
              <span className="leading-normal">최신순</span>
            </button>
            <button
              onClick={() => setSort(SortOptions.TOTAL_VIEWS, OrderOptions.DESC)}
              className={`w-full flex-1 pl-2 ${sort == SortOptions.TOTAL_VIEWS ? "text-alco-mint" : "text-gray-400"}`}
            >
              <span className="leading-normal">인기순</span>
            </button>
          </div>
        </div>
        {results.length === 0 && isFetching ? (
          <div className="flex-1 flex justify-center items-center">
            <Spinner />
          </div>
        ) : error ? (
          <div>오류가 발생했습니다.</div>
        ) : results.length === 0 ? (
          <NoData />
        ) : (
          <>
            <div className="md:grid md:grid-cols-2 md:gap-x-12">
              {results.map((item, index) => (
                <div className="p-2" key={index}>
                  <div
                    className="flex items-center gap-4 cursor-pointer"
                    key={index}
                    onClick={() => {
                      navigate(`/content/${item.id}?type=${item.type}`);
                    }}
                  >
                    <div className="flex-auto max-w-[100px] lg:max-w-[120px]">
                      <img
                        src={item.image}
                        alt={`search result ${index}`}
                        className={"object-contain rounded-[20px] w-full"}
                      />
                    </div>
                    <div className="flex-1 flex flex-col gap-[0.15rem]">
                      <div className="">{item.name}</div>
                      <div className="flex gap-1.5 text-xs text-gray-500">
                        {type == null && <span>{item.type == 0 ? "웹툰" : "웹소설"}</span>}
                        {type == null && <span>•</span>}
                        <span>{formatGenre(item.genres)}</span>
                      </div>
                      <div className="flex gap-1.5 text-xs text-gray-500">
                        <span>{`총 ${item.validEpisodeCount}화`}</span>
                        <span>•</span>
                        <span>{getStatus(item.status)}</span>
                      </div>
                      <div className="flex gap-1.5 text-xs">
                        <div className="inline-flex items-center gap-0.5 text-gray-500">
                          <UserIcon className="w-3 h-full" />
                          <span className="leading-normal">{formatView(item.total_views)}</span>
                        </div>
                        <div className="inline-flex items-center gap-0.5 text-red-500">
                          <StarIcon className="w-3 h-full" />
                          <span className="leading-normal">{formatScore(item.score)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="h-10" ref={observerRef}>
              {results.length > 0 && isFetching &&
                <div className="flex-1 flex justify-center items-center py-5">
                  <Spinner />
                </div>
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};
