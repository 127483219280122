import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from "@context/UserContext";
import { PaymentContext } from "@context/PaymentContext";
import { Loading } from "@components/Loading";
import { useConfirmTossMutation } from '@src/redux/servicesNew/payment';


export const Complete = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, session_token } = useContext(UserContext);
  // const [msg, setMsg] = useState('');

  const query = new URLSearchParams(location.search);
  const orderId = query.get('orderId');
  const paymentKey = query.get('paymentKey');
  const paymentType = query.get('paymentType');
  const amount = query.get('amount');
  const redirect = query.get('redirect');

  const [confirmToss, { isSuccess, error, isLoading }] = useConfirmTossMutation();

  useEffect(() => {
    if (userId === -1) {
      navigate(`/webtoon`);
    }
    if (orderId !== null && paymentKey !== null && amount !== null) {
      requestPay()
    }
  }, [orderId, paymentKey, amount])

  const requestPay = async () => {
    if (!orderId || !paymentKey || !amount) {
      return;
    }
    try {
      await confirmToss({ paymentKey, orderId, amount: Number(amount) });
    } catch (error) {
      alert('결제 실패 했습니다.')
      console.log('결제 검증에러:', error)
    } finally {
      navigate(`/me`);
    }
  }


  return (
    <div>
      <div className="w-full h-screen flex flex-col justify-center align-center">
        <Loading />
        {/*<div className="text-lg font-bold">{msg}</div>*/}
      </div>
    </div>
  );
}

export default Complete;
