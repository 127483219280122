import { SiteDomain } from "@src/redux/enums/domain";

const bannerCategoryMap: Record<SiteDomain, string> = {
    0: "general",
    1: "lpoint",
    2: "shinhan",
}

/** 배너 카테고리 반환
 * - 0(MAIN): `general`
 * - 1(LPOINT): `lpoint`
 * - 2(SHINHAN): `shinhan`
 */
export const getBannerCategory = (domain: SiteDomain): string => {
    return bannerCategoryMap[domain] || "general";
}