import React from "react";

interface ColorIconProps {
    fill?: string;
    stroke?: string;
    strokeWidth?: number;
    onClick?: () => void;
    className?: string;
}

const ColorIcon: React.FC<ColorIconProps> = ({ fill, stroke, strokeWidth, onClick, className }) => {

    return (
        <svg
            className={className}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={strokeWidth}
            viewBox="0 0 40 40"
            onClick={onClick}
        >
            <circle cx="20" cy="20" r="19.5" fill={fill} stroke="#8E8E8E" />
            <path
                d="M22.294 12.768H23.638V27.248H22.294V12.768ZM23.27 18.64H25.942V19.744H23.27V18.64ZM18.598 14.336H19.894C19.894 18.448 18.006 22.16 13.334 24.512L12.598 23.488C16.598 21.456 18.598 18.368 18.598 14.544V14.336ZM13.27 14.336H19.286V15.424H13.27V14.336Z"
                fill={stroke}
            />
        </svg>
    );
};

export default ColorIcon;