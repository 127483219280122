import arrayUniq from 'array-uniq';
import { t } from 'i18next';

/** 장르 배열을 `"a, b, c"` 형태로 포맷
 * 
 * - `arrayUniq`: 중복 데이터 제거 
 * - `t`: 다국어 설정
 */
export const formatGenre = (arr?: string[]): string => {
  if (!arr) {
    return "";
  }
  return arrayUniq(arr).map((genre) => t(`common.genre.${genre.toLowerCase()}`)).join(", ");
}