import BackIcon from "@components/icons/BackIcon";
import ColorIcon from "@components/icons/ColorIcon";
import { Spinner } from "@components/Spinner";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import useWindowSize from "@hooks/useWindowSize";
import Epub, { Rendition } from "epubjs";
import { TouchEvent, MouseEvent, useEffect, useRef, useState } from "react";
import "./epub-loader.css";

const viewerColors = [
    { backgroundColor: "white", color: "black", navigatorColor: "#D0D0D0" },
    { backgroundColor: "#D2D2D2", color: "black", navigatorColor: "black" },
    { backgroundColor: "#F6EBCF", color: "black", navigatorColor: "black" },
    { backgroundColor: "#FEDAF4", color: "black", navigatorColor: "black" },
    { backgroundColor: "#B7E6FA", color: "black", navigatorColor: "black" },
    { backgroundColor: "#0B332F", color: "#B1E3D7", navigatorColor: "#B1E3D7" },
    { backgroundColor: "#313131", color: "#BFBFBF", navigatorColor: "#BFBFBF" },
    { backgroundColor: "#000000", color: "white", navigatorColor: "white" },
];

const viewerFonts = [
    "NanumBarunGothic",
    "KoPubWorldBatang",
    "NanumBarunpen",
    "Cafe24SsurroundAir",
    "GyeonggiBatang",
    "UhBeeMiMi",
];

export const EpubLoader = ({ src }: { src: string; }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const viewerRef = useRef(null);
    const [rendition, setRendition] = useState<Rendition>();
    const [settingOpen, setSettingOpen] = useState(false);
    const [fontListOpen, setFontListOpen] = useState(false);
    const [startX, setStartX] = useState<number>(0);
    const [endX, setEndX] = useState<number>(0);

    const [colors, setColors] = useState<{ backgroundColor: string; color: string, navigatorColor: string }>(viewerColors[0]);
    const [fontFamily, setFontFamily] = useState("NanumBarunGothic");
    const [fontSize, setFontSize] = useState(3);
    const [lineHeight, setLineHeight] = useState(3);
    const [padding, setPadding] = useState(3);

    const { width } = useWindowSize();

    useEffect(() => {
        if (!rendition) {
            return;
        }
        const style = {
            body: {
                "font-size": `${fontSize * 5}px !important`,
                "line-height": `${lineHeight * 0.6}em !important`,
                color: `${colors.color} !important`,
            },
            p: {
                "line-height": `${lineHeight * 0.6}em !important`,
                color: `${colors.color} !important`,
            },
        };
        rendition.themes.default(style);
        rendition.themes.font(fontFamily);
    }, [fontFamily, fontSize, lineHeight, colors]);

    useEffect(() => {
        if (!rendition) {
            return;
        }
        const currentLocation = rendition.currentLocation() as any;
        if (currentLocation && currentLocation.start && currentLocation.start.cfi) {
            const currentCfi = currentLocation.start.cfi;
            rendition.display(currentCfi);
        }
    }, [padding]);

    useEffect(() => {
        if (!src || !viewerRef.current) {
            return;
        }
        const content = Epub(src);
        setRendition(content.renderTo(viewerRef.current, {
            width: "100%",
            height: "100%",
            spread: "both",
        }));
        content.opened
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setError(error.message);
                setLoading(false);
            });

        return () => {
            content.destroy();
        }
    }, [src, viewerRef]);

    useEffect(() => {
        if (!rendition) {
            return;
        }
        rendition.display();
        return () => {
            rendition.destroy();
        };
    }, [rendition]);

    const handleTouchStart = (event: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => {
        setStartX('touches' in event ? event.touches[0].clientX : event.clientX);
        setEndX('touches' in event ? event.touches[0].clientX : event.clientX);
    }

    const handleTouchMove = (event: TouchEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => {
        setEndX('touches' in event ? event.touches[0].clientX : event.clientX);
    }

    const handleTouchEnd = () => {
        if (!rendition) {
            return;
        }
        const diff = startX - endX;
        if (diff > 50) {
            rendition.next();
        }
        else if (diff < -50) {
            rendition.prev();
        }
        setStartX(0);
        setEndX(0);
    }


    return (
        <div
            className="self-center flex justify-center w-full h-full select-none"
            style={{
                backgroundColor: `${colors.backgroundColor}`,
                color: `${colors.color}`,
                paddingRight: `${padding * (width / 130)}px`,
                paddingLeft: `${padding * (width / 130)}px`
            }}>
            {/* 로딩바 */}
            {loading &&
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                    <Spinner />
                </div>
            }
            {/* 컨텐츠 */}
            <div className={`relative flex justify-center items-center w-full h-full transition-opacity duration-300 lg:px-10 ${loading ? "opacity-0" : "opacity-100"}`} >
                <div
                    ref={viewerRef}
                    className="h-full w-[90%]"
                />
                <div className="absolute flex w-[90%] h-full z-10">
                    <div className="h-full flex-1"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        onMouseDown={handleTouchStart}
                        onMouseMove={handleTouchMove}
                        onMouseUp={handleTouchEnd}
                        onClick={() => rendition && rendition.prev()}
                    >
                    </div>
                    <div className="h-full flex-1"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}
                        onMouseDown={handleTouchStart}
                        onMouseMove={handleTouchMove}
                        onMouseUp={handleTouchEnd}
                        onClick={() => rendition && rendition.next()}
                    >
                    </div>
                </div>
            </div>
            {error &&
                <div className="flex items-center justify-center h-10">
                    <span className="text-black">컨텐츠를 불러오지 못하였습니다.</span>
                </div>
            }
            {/* 설정, 처음으로 아이콘 */}
            <div className="absolute inset-0 flex flex-col justify-end items-end max-w-[1200px] mx-auto gap-5 right-2 bottom-20">
                <img src="/setting.png" className="cursor-pointer z-20 w-10 h-10 xl:w-16 xl:h-16" onClick={() => setSettingOpen((settingOpen) => !settingOpen)} />
                <BackIcon className="cursor-pointer z-20 w-10 h-10 xl:w-16 xl:h-16" onClick={() => rendition && rendition.display()} />
            </div>
            {/* 설정 모달 */}
            {settingOpen &&
                <div className="absolute inset-0 flex justify-end items-end max-w-[1100px] mx-auto px-12 bottom-20" onClick={() => setSettingOpen(false)}>
                    <div className="flex flex-col w-full rounded-xl shadow-md z-20 bg-white text-black gap-4 p-5" onClick={(e) => e.stopPropagation()}>
                        <div className="flex justify-center items-center gap-2">
                            <span className="flex-[1]">배경색</span>
                            <div className="flex-[5] overflow-x-scroll whitespace-nowrap text-center">
                                {viewerColors.map((color, index) =>
                                    <ColorIcon
                                        className="inline cursor-pointer w-8 h-8 ml-2"
                                        key={index}
                                        fill={color.backgroundColor}
                                        stroke={color.color}
                                        onClick={() => setColors(color)}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="flex-[1]">글꼴</span>
                            <div className="flex-[5] relative">
                                <button
                                    className="font-medium rounded-lg text-center inline-flex justify-end items-center w-full text-sm px-5 py-2.5"
                                    type="button"
                                    onClick={() => setFontListOpen((fontListOpen) => !fontListOpen)}>
                                    {fontFamily}
                                    <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                    </svg>
                                </button>
                                {fontListOpen &&
                                    <div className="w-full flex justify-end">
                                        <div className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow">
                                            <ul className="py-2 text-sm text-gray-700">
                                                {viewerFonts.map((font, index) =>
                                                    <li key={index}>
                                                        <div className="px-4 py-2 hover:bg-gray-100" onClick={() => setFontFamily(font)}>{font}</div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="">글자 크기</span>
                            <div className="flex justify-between items-center text-alco-mint gap-4">
                                <MinusCircleIcon className="cursor-pointer w-10 h-10 stroke-1"
                                    onClick={() => fontSize > 1 && setFontSize(fontSize - 1)} />
                                <span className="text-black">{fontSize}</span>
                                <PlusCircleIcon className="cursor-pointer w-10 h-10 stroke-1"
                                    onClick={() => fontSize < 5 && setFontSize(fontSize + 1)} />
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="">줄 간격</span>
                            <div className="flex justify-between items-center text-alco-mint gap-4">
                                <MinusCircleIcon className="cursor-pointer w-10 h-10 stroke-1"
                                    onClick={() => lineHeight > 1 && setLineHeight(lineHeight - 1)} />
                                <span className="text-black">{lineHeight}</span>
                                <PlusCircleIcon className="cursor-pointer w-10 h-10 stroke-1"
                                    onClick={() => lineHeight < 5 && setLineHeight(lineHeight + 1)} />
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="">여백</span>
                            <div className="flex justify-between items-center text-alco-mint gap-4">
                                <MinusCircleIcon className="cursor-pointer w-10 h-10 stroke-1"
                                    onClick={() => padding > 1 && setPadding(padding - 1)} />
                                <span className="text-black">{padding}</span>
                                <PlusCircleIcon className="cursor-pointer w-10 h-10 stroke-1"
                                    onClick={() => padding < 5 && setPadding(padding + 1)} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};