import { ApiResponse } from "../interfaces/api";
import { CancelTossReq, CancelTossRes } from "../interfaces/payment/cancelToss";
import { ConfirmTossReq, ConfirmTossRes } from "../interfaces/payment/confirmToss";
import { allComicsApi } from "./api";

export const paymentApi = allComicsApi.injectEndpoints({
    endpoints: (builder) => ({
        /** TossPayment 결제 확인 */
        confirmToss: builder.mutation<ApiResponse<ConfirmTossRes>, ConfirmTossReq>({
            query: (req) => ({
                url: `/v1/client/web-site/payment/confirm`,
                method: "POST",
                body: req,
            }),
            invalidatesTags: ['Payment'],
        }),
        /** TossPayment 결제 취소 */
        cancelToss: builder.mutation<ApiResponse<CancelTossRes>, CancelTossReq>({
            query: (req) => ({
                url: `/v1/client/web-site/payment/cancel`,
                method: "POST",
                body: req,
            }),
            invalidatesTags: ['Payment'],
        }),
    })
});

export const {
    useConfirmTossMutation,
    useCancelTossMutation,
} = paymentApi;