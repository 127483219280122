import { ContentStatus } from "@src/redux/enums/content";

const statusMap: Record<ContentStatus, string> = {
    1: "연재 중",
    2: "완결",
    3: "휴재",
}

/** 연재 상태 반환
 * - 1: 연재 중
 * - 2: 완결
 * - 3: 휴재
 */
export const getStatus = (status: ContentStatus): string => {
    return statusMap[status] || "연재 중지";
}