import { XMarkIcon } from "@heroicons/react/24/solid";
import { usePurchaseContentMutation } from "@src/redux/servicesNew/content";
import { ContentTypes, PurchaseTypes } from "@src/redux/enums/content";
import { GetEpisodeCutItem } from "@src/redux/interfaces/content/getEpisodeCut";
import { GetEpisodeListItem } from "@src/redux/interfaces/content/getEpisodeList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetBalanceQuery } from "@src/redux/servicesNew/user";
import { PurchaseFailedModal } from "./PurchaseFailedModal";
import { Spinner } from "@components/Spinner";
import { useStorage } from "@hooks/useStorage";

export const PurchaseModal = ({ episode, type, onClose, expired }: { episode: GetEpisodeListItem | GetEpisodeCutItem; type: ContentTypes; onClose: () => void; expired?: boolean; }) => {
    const [purchaseType, setPurchaseType] = useState<PurchaseTypes>(PurchaseTypes.RENTAL);
    const [purchaseFailedModalOpen, setPurchaseFailedModalOpen] = useState(false);
    const { isLoggedIn } = useStorage();
    const navigate = useNavigate();

    const [purchaseContent, { isSuccess: pcIsSuccess, error: pcError, isLoading: pcIsLoading }] = usePurchaseContentMutation();
    const { data: bData, error: bError, isFetching: bIsFetching } = useGetBalanceQuery({}, { skip: !isLoggedIn });

    useEffect(() => {
        if (pcIsSuccess) {
            // Issue: 상태 변화 감지되지 않는 문제
            console.log("success");
        }
    }, [pcIsSuccess]);

    useEffect(() => {
        if (pcError) {
            console.log(pcError);
        }
    }, [pcError]);

    const handlePurchase = async () => {
        if (!isLoggedIn) {
            return navigate('/login');
        }
        if (!bData) {
            return;
        }
        if ((purchaseType == PurchaseTypes.COLLECTION && bData.data.data < episode.displayPrice.buy)
            || (purchaseType == PurchaseTypes.RENTAL && bData.data.data < episode.displayPrice.rent)) {
            setPurchaseFailedModalOpen(true);
            return;
        }
        // Issue: EpisodeCut 과 EpsiodeList 의 id와 titleId 프로퍼티명 혼동
        const result = await purchaseContent({
            ID: Number(episode.titleId || episode.id),
            EpisodeID: episode.titleID || episode.id,
            ContentType: type,
            PurchaseType: purchaseType,
        }).unwrap();
        if (result.code === 200) {
            navigate(`/content/episode/${episode.titleID || episode.id}?type=${type}`);
            onClose();
        }
        else {
            // Todo: 예외 처리
        }
    }

    const backdropClick = () => {
        if (!purchaseFailedModalOpen) {
            onClose();
        }
    }

    return (
        <div className="w-screen h-screen fixed z-30 bg-black/20 font-notokr" onClick={backdropClick}>
            {/* 구매 실패 모달 */}
            {purchaseFailedModalOpen &&
                <PurchaseFailedModal onClose={() => setPurchaseFailedModalOpen(false)} />
            }
            <div className="flex w-full h-full justify-center items-center px-2">
                <div className="flex flex-col bg-white text-black w-full overflow-hidden z-30 rounded-lg shadow-md max-w-[600px]" onClick={(e) => e.stopPropagation()}>
                    <div className="flex justify-between items-center bg-alco-mint text-white p-3">
                        <span className="font-semibold text-lg pl-3">작품 구매</span>
                        <button onClick={onClose}>
                            <XMarkIcon className="w-8 h-8" />
                        </button>
                    </div>
                    <div className="flex flex-col items-center gap-4 p-6 py-16">
                        <div className="self-start">
                            <span className="">{episode.name}</span>
                        </div>
                        <div className="flex flex-col w-full gap-3 px-2">
                            <div
                                className={`flex justify-between rounded-lg p-3 py-5 ${purchaseType === PurchaseTypes.RENTAL ? "bg-alco-mint/30" : "bg-gray-100"}`}
                                onClick={() => setPurchaseType(PurchaseTypes.RENTAL)}
                            >
                                <span>3일 대여</span>
                                <span className="text-alco-mint">{episode.displayPrice.rent}코인</span>
                            </div>
                            <div
                                className={`flex justify-between rounded-lg p-3 py-5 ${purchaseType === PurchaseTypes.COLLECTION ? "bg-alco-mint/30" : "bg-gray-100"}`}
                                onClick={() => setPurchaseType(PurchaseTypes.COLLECTION)}
                            >
                                <span>소장</span>
                                <span className="text-alco-mint">{episode.displayPrice.buy}코인</span>
                            </div>
                        </div>
                        {expired ?
                            <div className="self-end">
                                <span className="text-red-500">대여 기간이 만료되었습니다.</span>
                            </div> : null}
                        <div className="self-start flex flex-col text-[0.65rem] text-gray-500 gap-1">
                            <span>• 대여권, 소장권을 구매한 작품은 구매 취소하거나 환불을 받을 수 없습니다.</span>
                            <span>• 결제에 대한 자세한 문의는 [고객 지원] 페이지에서 문의해주시기 바랍니다.</span>
                        </div>
                        <div className="self-end flex">
                            <button
                                className={`bg-alco-mint text-white rounded-lg px-3 py-2 ${pcIsLoading && "pointer-events-none"}`}
                                onClick={handlePurchase}
                            >
                                <span>{pcIsLoading ? <Spinner /> : purchaseType === PurchaseTypes.RENTAL ? "대여하기" : "구매하기"}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}