import { ApiResponse } from "../interfaces/api";
import { GetBalanceRes } from "../interfaces/user/getBalance";
import { allComicsApi } from "./api";

export const userApi = allComicsApi.injectEndpoints({
    endpoints: (builder) => ({
        /** 보유 코인 조회 */
        getBalance: builder.query<ApiResponse<GetBalanceRes>, any>({
            query: () => ({
                url: '/v1/client/web-site/payment/balance',
                method: "POST",
            }),
            providesTags: ['Purchase', 'Payment'],
            onCacheEntryAdded: async (req, { cacheDataLoaded }) => {
                const { data, meta } = await cacheDataLoaded;
                console.log('cached data:', meta?.request.url, data);
            },
        }),
    })
});

export const {
    useGetBalanceQuery
} = userApi;